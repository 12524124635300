:root {
    --border-color: #ebedf2;
    --primary: #0A6375;
    --secondary: #111828;
    --background: #f2edf3;
    --danger: #e63946;
}

// PRIMENG CONFIG

.p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px var(--primary);
    border-color: var(--primary);
}

.p-button:enabled:active {
    background: var(--secondary);
    color: #ffffff;
    border-color: var(--secondary);
}

.p-button:enabled:hover {
    background: var(--secondary);
    color: #ffffff;
    border-color: var(--secondary);
}

.p-button:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px var(--primary), 0 1px 2px 0 rgba(0, 0, 0, 0);
}

.p-button {
    color: #ffffff;
    background: var(--primary);
    border: 1px solid var(--primary);
    padding: 0.75rem 1rem;
    font-size: 1rem;
    transition: none;
    border-radius: 0.375rem;
}

.p-selectbutton .p-button.p-highlight {
    background: var(--primary);
    border-color: var(--primary);
    color: #ffffff;
}

.p-selectbutton .p-button.p-highlight:hover {
    background: var(--primary);
    border-color: var(--primary);
    color: #ffffff;
}

// .p-dropdown:not(.p-disabled).p-focus {
//     outline: 0 none;
//     outline-offset: 0;
//     box-shadow: 0 0 0 1px var(--primary);
//     border-color: var(--primary);
// }

.p-fileupload .p-fileupload-buttonbar {
    background: #fafafa;
    padding: 1.25rem;
    border: 1px solid #e5e7eb;
    color: #3f3f46;
    border-bottom: 0 none;
    border-top-right-radius: 0.375rem;
    border-top-left-radius: 0.375rem;
}

.p-fileupload .p-fileupload-buttonbar .p-button {
    margin-right: 0.5rem;
}

.p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-choose.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px var(--primary);
}

.p-fileupload .p-fileupload-content {
    background: #ffffff;
    padding: 2rem 1rem;
    border: 1px solid #e5e7eb;
    color: #3f3f46;
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
}

.p-fileupload .p-progressbar {
    height: 0.25rem;
}

.p-fileupload .p-fileupload-row>div {
    padding: 1rem 1.5rem;
}

.p-fileupload.p-fileupload-advanced .p-message {
    margin-top: 0;
}

.p-fileupload-choose:not(.p-disabled):hover {
    background: var(--primary);
    color: #ffffff;
    border-color: var(--primary);
}

.p-fileupload-choose:not(.p-disabled):active {
    background: var(--primary);
    color: #ffffff;
    border-color: var(--primary);
}

.p-button.p-button-text {
    background-color: transparent;
    color: var(--primary);
    border-color: transparent;
}

.p-button.p-button-text:enabled:hover {
    background: rgba(79, 70, 229, 0.04);
    color: var(--primary);
    border-color: transparent;
}

.p-checkbox {
    width: 16px;
    height: 16px;
}

.p-checkbox .p-checkbox-box {
    border: 2px solid #d4d4d8;
    background: #ffffff;
    width: 16px;
    height: 16px;
    color: #3f3f46;
    border-radius: 0.375rem;
    transition: none;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
    transition-duration: 0.2s;
    color: #ffffff;
    font-size: 10px;
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: var(--primary);
    background: var(--primary);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: #d4d4d8;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px var(--primary);
    border-color: var(--primary);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: var(--primary);
    background: var(--primary);
    color: #ffffff;
}

p-checkbox.ng-dirty.ng-invalid>.p-checkbox>.p-checkbox-box {
    border-color: #f0a9a7;
}

.p-input-filled .p-checkbox .p-checkbox-box {
    background-color: #fafafa;
}

.p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
    background: var(--primary);
}

.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    background-color: #fafafa;
}

.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    background: var(--primary);
}

.p-checkbox-label {
    margin-left: 0.5rem;
}

.p-dropdown {
    background: #ffffff;
    border: 1px solid #d4d4d8;
    transition: none;
    border-radius: 0.375rem;
}

.p-dropdown:not(.p-disabled):hover {
    border-color: #d4d4d8;
}

.p-dropdown:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px var(--primary);
    border-color: var(--primary);
}

.p-dropdown.p-dropdown-clearable .p-dropdown-label {
    padding-right: 1.75rem;
}

.p-dropdown .p-dropdown-label {
    background: transparent;
    border: 0 none;
}

.p-dropdown .p-dropdown-label.p-placeholder {
    color: #71717A;
}

.p-dropdown .p-dropdown-label:enabled:focus {
    outline: 0 none;
    box-shadow: none;
}

.p-dropdown .p-dropdown-trigger {
    background: transparent;
    color: #71717A;
    width: 3rem;
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
}

.p-dropdown .p-dropdown-clear-icon {
    color: #71717A;
    right: 3rem;
}

.p-dropdown-panel {
    background: #ffffff;
    color: #3f3f46;
    border: 0 none;
    border-radius: 0.375rem;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.p-dropdown-panel .p-dropdown-header {
    padding: 0.5rem 0.75rem;
    border-bottom: 0 none;
    color: #3f3f46;
    background: #fafafa;
    margin: 0;
    border-top-right-radius: 0.375rem;
    border-top-left-radius: 0.375rem;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
    padding-right: 1.75rem;
    margin-right: -1.75rem;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
    right: 0.75rem;
    color: #71717A;
}

.p-dropdown-panel .p-dropdown-items {
    padding: 0.25rem 0;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    margin: 0;
    padding: 0.75rem 1rem;
    border: 0 none;
    color: #3f3f46;
    background: transparent;
    transition: none;
    border-radius: 0;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: var(--primary);
    background: #EEF2FF;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    color: #18181B;
    background: #f4f4f5;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
    padding: 0.75rem 1rem;
    color: #3f3f46;
    background: transparent;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
    margin: 0;
    padding: 0.75rem 1rem;
    color: #3f3f46;
    background: #ffffff;
    font-weight: 600;
}

p-dropdown.ng-dirty.ng-invalid>.p-dropdown {
    border-color: #f0a9a7;
}

.p-progressbar {
    border: 0 none;
    height: 1.5rem;
    background: #e5e7eb;
    border-radius: 0.375rem;
}

.p-progressbar .p-progressbar-value {
    border: 0 none;
    margin: 0;
    background: var(--primary);
}

.p-progressbar .p-progressbar-label {
    color: #ffffff;
    line-height: 1.5rem;
}

// PRIMENG CONFIG

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 600 !important;
    color: var(--primary)
}

.link-button {
    color: var(--primary);
}

.link-button:hover {
    cursor: pointer;
    text-decoration: underline;
}

.brand-logo {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    column-gap: 8px;
}

.container-pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: fit-content;
}

.buttonRounded {
    color: #ffffff;
    background: var(--primary);
    border: 1px solid var(--primary);
    ;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    transition: none;
    border-radius: 50%;
    width: 3.5rem;
}

.buttonRounded:enabled:hover {
    transition: all .3s ease;
    background: var(--secondary);
    color: #ffffff;
    border-color: var(--secondary);
}

.buttonRounded:enabled:active {
    background: var(--secondary);
    ;
    color: #ffffff;
    border-color: #002d3b;
}


.buttonLogin {
    color: #ffffff;
    background: var(--primary);
    border: 1px solid var(--primary);
    ;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    transition: none;
    border-radius: 0.375rem;
    width: 100%;
}

.buttonLogin:enabled:hover {
    transition: all .3s ease;
    background: var(--secondary);
    color: #ffffff;
    border-color: var(--secondary);
}

.buttonLogin:enabled:active {
    background: var(--secondary);
    ;
    color: #ffffff;
    border-color: #002d3b;
}

.button {
    color: #ffffff;
    background: var(--primary);
    ;
    border: 1px solid var(--primary);
    ;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    transition: none;
    border-radius: 0.375rem;
}

.button:enabled:hover {
    transition: all .3s ease;
    background: var(--secondary);
    color: #ffffff;
    border-color: var(--secondary);
}

.button:enabled:active {
    background: var(--secondary);
    ;
    color: #ffffff;
    border-color: var(--secondary);
}

.button-outlined {
    background-color: transparent;
    color: var(--primary);
    ;
    border: 1px solid var(--primary);
    ;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    transition: none;
    border-radius: 0.375rem;
}

.button-outlined:enabled:hover {
    background: var(--secondary);
    color: #fff;
    border: 1px solid;
}

.button-outlined:enabled:active {
    background: var(--secondary);
    color: #fff;
    border: 1px solid;
}

//

.button-outlined-login {
    background-color: transparent;
    color: var(--primary);
    ;
    border: 1px solid var(--primary);
    ;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    transition: none;
    border-radius: 0.375rem;
    width: 100%;
}

.button-outlined:enabled:hover {
    background: var(--secondary);
    color: #fff;
    border: 1px solid;
}

.button-outlined:enabled:active {
    background: var(--secondary);
    color: #fff;
    border: 1px solid;
}

.contentActions {
    display: flex;
    flex-direction: row;
    justify-content: end;
    row-gap: 6px;
}

.input {
    margin: 8px;
}

.input-margin {
    margin-bottom: 8px;
}

.contentCheckbox {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}

.card {
    border-radius: 14px !important;
}


.contentActions {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.btn-action {
    height: 45px !important;
}


:host ::ng-deep {
    .p-paginator {
        .p-paginator-current {
            margin-left: auto;
        }
    }

    .p-progressbar {
        height: .5rem;
        background-color: #D8DADC;

        .p-progressbar-value {
            background-color: #607D8B;
        }
    }

    .table-header {
        display: flex;
        justify-content: space-between;
    }

    .p-calendar .p-datepicker {
        min-width: 25rem;

        td {
            font-weight: 400;
        }
    }

    .p-datatable.p-datatable-customized {
        .p-datatable-header {
            padding: 1rem;
            text-align: center;
            font-size: 1.5rem;
        }

        .p-paginator {
            padding: 1rem;
        }

        .p-datatable-thead>tr>th {
            text-align: center;
            font-size: 12px;
            font-weight: bold;
        }

        .p-datatable-tbody>tr>td {
            text-align: left;
            color: #9D9B9B;
        }

        .p-dropdown-label:not(.p-placeholder) {
            text-transform: uppercase;
        }
    }

    .p-datatable.p-datatable-customized:not(.p-datatable-gridlines) {
        .p-datatable-tbody>tr>td {
            cursor: pointer;
        }
    }

    /* Responsive */
    .p-datatable-customized .p-datatable-tbody>tr>td .p-column-title {
        display: none;
    }
}

.datatable-customized-badge {
    padding: 10px !important;
    font-family: 'Poppins' !important;
    border-radius: 30px !important;
    font-size: 11px !important;
    font-weight: 300 !important;

    &.status-qualified {
        background: #C8E6C9;
        color: #256029;
    }

    &.status-unqualified {
        background: #FFCDD2;
        color: #C63737;
    }

    &.status-negotiation {
        background: #FEEDAF;
        color: #8A5340;
    }

    &.status-new {
        background: #B3E5FC;
        color: #23547B;
    }

    &.status-renewal {
        background: #ECCFFF;
        color: #694382;
    }

    &.status-proposal {
        background: #FFD8B2;
        color: #805B36;
    }

    &.status-passive {
        background: #FFD8B2;
        color: #805B36;
    }
}

.product-badge {
    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;

    &.status-instock {
        background: #C8E6C9;
        color: #256029;
    }

    &.status-outofstock {
        background: #FFCDD2;
        color: #C63737;
    }

    &.status-lowstock {
        background: #FEEDAF;
        color: #8A5340;
    }
}

.order-badge {
    border-radius: 2px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;

    &.order-delivered {
        background: #C8E6C9;
        color: #256029;
    }

    &.order-cancelled {
        background: #FFCDD2;
        color: #C63737;
    }

    &.order-pending {
        background: #FEEDAF;
        color: #8A5340;
    }

    &.order-returned {
        background: #ECCFFF;
        color: #694382;
    }
}

.global-filter-label {
    font-size: 1rem;
    font-weight: normal;

    .p-float-label input:focus~label {
        font-size: 12px;
    }
}

@media screen and (max-width: 960px) {
    :host ::ng-deep {
        .p-datatable {
            &.p-datatable-customized {

                .p-datatable-thead>tr>th,
                .p-datatable-tfoot>tr>td {
                    display: none !important;
                }

                .p-datatable-tbody>tr {
                    border-bottom: 1px solid var(--surface-d);

                    >td {
                        text-align: left;
                        display: block;
                        border: 0 none !important;
                        width: 100% !important;
                        float: left;
                        clear: left;
                        border: 0 none;

                        .p-column-title {
                            text-align: center;
                            padding: .4rem;
                            min-width: 30%;
                            display: inline-block;
                            margin: -.4rem 1rem -.4rem -.4rem;
                            font-weight: bold;
                        }

                        .p-progressbar {
                            margin-top: .5rem;
                        }
                    }
                }
            }
        }
    }

}

@media screen and (max-width: 960px) {
    :host ::ng-deep .p-datatable.p-datatable-customized.rowexpand-table .p-datatable-tbody>tr>td:nth-child(6) {
        display: flex;
    }
}

.field-checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.labelCheckbox {
    margin-top: 9px;
}